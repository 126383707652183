.container {
  max-width: 1300px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-nav-bar {
  margin-top: 2em;
  border-radius: 0px 40px 40px 0px;
}

div .my-nav-div-wrapper {
  justify-content: flex-end;
}

.my-bg-color {
  background: #0c0d0f;
}
.my-nav-bg-color,
.dropdown-menu {
  background: #1f252b;
}

.text-grey {
  color: #7a7a7b;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .text-grey {
    width: 100%;
  }
}

.logo-width {
  width: 30%;
}
.mein-test {
  justify-content: space-between;
}

.table-flight-monitor {
  margin-top: 5em;
  font-size: 0.7em;
}

.flight-monitor-td {
  background: #1f252b;
  border: 2px solid #0c0d0f;
  color: #7a7a7b;
  font-weight: bold;
  text-align: center;

  min-width: 1em;
  max-width: 1em;
  height: 2em;
}

.flight-monitor-td-empty {
  background: #0c0d0f;

  min-width: 1em;
  max-width: 1em;
}

.flight-monitor-head {
  color: #000;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  background-color: #feed47;
  border-radius: 20px 20px 20px 20px;
}

.lang-flags {
  justify-content: flex-end;
  width: 2em;
  margin: 1em 1em 0em 0em;
}

.current_time {
  color: white;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.current_time .wrap-time {
  width: 30%;
}

.cooltextblockwrap {
  margin-top: 3em;
}
.cooltextblocktitle {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.cooltextblocktext {
  color: #ddd;
  font-size: 0.8em;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}
.cooltextblockwrap div div {
  width: 50em;
}

.ASU {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  color: #ddd;
}

.ASU-ELEMENT {
  text-align: center;
  padding: 2em;
  border-radius: 20px 20px 20px 20px;
  background: #1f252b;
  width: 60%;
}
.ASU-ELEMENT .form-group {
  margin-top: 1em;
}

.ASU-ELEMENT .buttons {
  display: flex;
  justify-content: center;
  /* text-align: center; */
}

.ASU-ELEMENT .buttons button {
  margin: 1em;
}

.privacy-policy-click {
  cursor: pointer;
}
.privacy-policy {
  text-align: left;
}
